import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
import logo from './static/logo.png'; // Make sure to provide the correct path to your logo
import { useUser } from '../../UserContext';
import PortalIconWhite from './static/portal-icon-white.svg';
import PortfolioIconWhite from './static/portfolio-icon-white.svg';
import ExperiencedHiresIconWhite from './static/experienced-hires-white.svg';
import phgLogo from './static/phglogo.png';


function Header() {
  const { logoutUser, userProfile } = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  

  const toggleDropdown = () => {
	setDropdownOpen(!dropdownOpen);
  };
  
  useEffect(() => {
	  const handleResize = () => {
		setIsMobile(window.innerWidth < 768);
	  };
  
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);

  useEffect(() => {
	const handleOutsideClick = (event) => {
	  if (!event.target.closest('.profile-container') && dropdownOpen) {
		setDropdownOpen(false);
	  }
	};

	document.addEventListener('mousedown', handleOutsideClick);
	return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [dropdownOpen]);

  const handleLogout = async (e) => {
	e.preventDefault(); // Prevent the default behavior of the link
	const token = localStorage.getItem('authToken');

	try {
	  const response = await fetch('https://teneo.pretiumsearch.com/api/logout', {
		method: 'POST',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json',
		},
	  });

	  if (response.ok) {
		console.log("Logout successful");
	  } else {
		console.warn("Logout failed or wasn't necessary");
	  }
	} catch (error) {
	  console.error("Error during logout:", error);
	  alert("An error occurred during logout. You might be logged out, but please check.");
	} finally {
	  logoutUser();
	}
  };

  return (
	  <header className="header">
		<div className="header-content">
		  <img src={logo} alt="Pretium Logo" className="header-logo" />
		  <span className="vertical-line"></span> {/* You may need to add styles for this */}
		  <img src={phgLogo} alt="PHG Logo" className="header-client-logo" />
		</div>
		<nav className="header-nav">
		  {isMobile ? (
			<div className="profile-container">
			  <img
				src={userProfile.profilePicture}
				alt="Profile"
				className="profile-picture"
				onClick={toggleDropdown}
			  />
			  <FontAwesomeIcon icon={faChevronDown} className="dropdown-arrow" onClick={toggleDropdown} />
			  {dropdownOpen && (
				<div className="dropdown-menu">
				  <NavLink to="/teamportal" className="dropdown-item" activeClassName="active-link">
					Team Growth Portal
				  </NavLink>
				  <button className="dropdown-item" onClick={handleLogout}>Logout</button>
				</div>
			  )}
			</div>
		  ) : (
			<>
			  <NavLink to="/teamportal" className="nav-link" activeClassName="active-link">
				<img src={PortalIconWhite} alt="Portal Icon" className="nav-icon-portal" /> Team Growth Portal
			  </NavLink>
			  {userProfile.profilePicture && (
				<div className="profile-container">
				  <img
					src={userProfile.profilePicture}
					alt="Profile"
					className="profile-picture"
					onClick={toggleDropdown}
				  />
				  <FontAwesomeIcon icon={faChevronDown} className="dropdown-arrow" onClick={toggleDropdown} />
				  {dropdownOpen && (
					<div className="dropdown-menu">
					  <button className="dropdown-item" onClick={handleLogout}>Logout</button>
					</div>
				  )}
				</div>
			  )}
			</>
		  )}
		</nav>
	  </header>
	);
}

export default Header;
