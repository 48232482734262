// src/charts/FunnelChart.js

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const data = [
  { name: 'Considered', value: 2000 },
  { name: 'Reached-Out', value: 700 },
  { name: 'Responded', value: 120 },
  { name: 'Interested', value: 60 },
];

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7f50'];

const FunnelChart = () => (
  <div className="chart-container">
	<h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Candidate Funnel</h2>
	<ResponsiveContainer width="100%" height={150}>
	  <BarChart
		data={data}
		layout="vertical"
		margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
	  >
		<XAxis type="number" style={{ fontSize: 10 }} />
		<YAxis type="category" dataKey="name" style={{ fontSize: 8 }} />
		<Tooltip />
		<Bar dataKey="value">
		  {data.map((entry, index) => (
			<Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
		  ))}
		</Bar>
	  </BarChart>
	</ResponsiveContainer>
  </div>
);

export default FunnelChart;
