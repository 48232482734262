import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import Registration from './components/Registration/Registration';
import Portfolio from './components/Portfolio/Portfolio';
import AdvisorPortal from './components/AdvisorPortal/Portal';
import TeamPortal from './components/TeamPortal/Portal'; // Importing the Portal component
import ExperiencedHiresPortal from './components/ExperiencedHiresPortal/Portal'; 


function RoutesBasedOnAuth() {
    const { isRegistered} = useUser(); 

    if (isRegistered) {
        return (
            <Routes>
                <Route path="/teamportal" element={<TeamPortal />} />
                <Route path="*" element={<Navigate to="/teamportal" />} /> 
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<Registration />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

function App() {
    return (
        <Router>
            <UserProvider>
                <Routes>
                    <Route path="*" element={<RoutesBasedOnAuth />} />
                </Routes>
            </UserProvider>
        </Router>
    );
}

export default App;
