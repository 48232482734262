// src/TeamPortal.js

import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import CandidateCard from './CandidateCard';
import LocationBarChart from './charts/LocationBarChart';
import StatusBarChart from './charts/StatusBarChart';
import FunnelChart from './charts/FunnelChart';
import CompensationBarChart from './charts/CompensationBarChart';
import { calculateMedian, processDataForSummary } from './utils/utils';
import './Portal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import listViewIcon from './static/list-view.svg';
import slideshowViewIcon from './static/slideshow-view.svg';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const TeamPortal = () => {
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isListView, setIsListView] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [filteredSlideshowCandidates, setFilteredSlideshowCandidates] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const statusDropdownRef = useRef(null);
  const tagsDropdownRef = useRef(null);
  const statusOptions = allStatuses.map(status => ({ value: status, label: status }));
  const tagOptions = allTags.map(tag => ({ value: tag, label: tag }));
  const [activeTab, setActiveTab] = useState('Summary');
  const [allLocations, setAllLocations] = useState([]);
  const [summaryData, setSummaryData] = useState(null);
  
  
  const statusPriority = {
	"Shortlist": 1,
	"Initial Interest": 2,
	"Not Interested": 3,
	"Not Qualified": 4,
  };
  
const sortCandidatesByStatus = (candidates) => {
	return candidates.sort((a, b) => {
	  const statusA = a.status.length > 0 ? a.status[0].name : "Other";
	  const statusB = b.status.length > 0 ? b.status[0].name : "Other";
	  const priorityA = statusPriority[statusA] || 5;
	  const priorityB = statusPriority[statusB] || 5;
  
	  // Primary sorting by status priority
	  if (priorityA !== priorityB) {
		return priorityA - priorityB;
	  }
  
	  // Secondary sorting by whether the files array is empty
	  const hasFilesA = a.files.length > 0;
	  const hasFilesB = b.files.length > 0;
  
	  return hasFilesB - hasFilesA; // Candidates with files come first
	});
  };


  
  useEffect(() => {
	if (candidates) {
	  const processedData = processDataForSummary(candidates);
	  setSummaryData(processedData);
	}
  }, [candidates]);
  
  const handleTabChange = (tab) => {
	setActiveTab(tab);
  };

  const toggleStatusDropdown = () => {
	setShowStatusDropdown(!showStatusDropdown);
  };
  
  const toggleTagsDropdown = () => {
	setShowTagsDropdown(!showTagsDropdown);
  };

  const handleClickOutside = (event) => {
	if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
	  setShowStatusDropdown(false);
	}
	if (tagsDropdownRef.current && !tagsDropdownRef.current.contains(event.target)) {
	  setShowTagsDropdown(false);
	}
  };
  
  useEffect(() => {
	document.addEventListener('click', handleClickOutside, true);
  
	return () => {
	  document.removeEventListener('click', handleClickOutside, true);
	};
  }, []);

  const getAllUniqueTagsAndStatuses = (candidates) => {
	const allTags = new Set();
	const allStatuses = new Set();
	const allLocations = new Set();
  
	candidates.forEach(candidate => {
	  candidate.tags?.forEach(tag => allTags.add(tag.name));
	  candidate.status?.forEach(status => allStatuses.add(status.name));
	  allLocations.add(candidate.location); 
	});
  
	return { allTags: Array.from(allTags), allStatuses: Array.from(allStatuses), allLocations: Array.from(allLocations) };
  };
  
 const handleSearchChange = (event) => {
   const newSearchTerm = event.target.value;

   const newFilteredCandidates = candidates.filter(candidate =>
	 (candidate.name?.toLowerCase() || "").includes(newSearchTerm.toLowerCase())
   );
 
   setSearchTerm(newSearchTerm);
   setFilteredSlideshowCandidates(newFilteredCandidates);
 
   if (currentSlide >= newFilteredCandidates.length) {
	 setCurrentSlide(newFilteredCandidates.length > 0 ? newFilteredCandidates.length - 1 : 0);
   }
 };
 
 const handleStatusChange = selectedOptions => {
   setSelectedStatuses(selectedOptions.map(option => option.value));
 };

 const handleTagChange = (event) => {
   const selectedOptions = Array.from(event.target.options)
								 .filter(option => option.selected)
								 .map(option => option.value);
 
   setSelectedTags(selectedOptions);
 };

  const handleNext = () => {
	setCurrentSlide(prev => {
	  if (filteredSlideshowCandidates.length === 0) return 0;
	  return (prev + 1) % filteredSlideshowCandidates.length;
	});
  };
  
  const handlePrev = () => {
	setCurrentSlide(prev => {
	  if (filteredSlideshowCandidates.length === 0) return 0;
	  return prev === 0 ? filteredSlideshowCandidates.length - 1 : prev - 1;
	});
  };
  
  useEffect(() => {
	if (currentSlide >= filteredSlideshowCandidates.length) {
	  setCurrentSlide(0);
	}
  }, [filteredSlideshowCandidates.length]);
  
  useEffect(() => {
	const fetchData = async () => {
	  try {
		const response = await fetch('https://teneo.pretiumsearch.com/api/fetchTeamCandidates', {
		  headers: {
			'Authorization': `Bearer ${localStorage.getItem('authToken')}`
		  }
		});
		const data = await response.json();
		
		console.log("data:", data);
		
		// Sort candidates by status
	  	const sortedData = sortCandidatesByStatus(data);
		  
		console.log("sortedData:", sortedData);
		
	  	setCandidates(sortedData);
		// setCandidates(data);
  
		const { allTags, allStatuses, allLocations } = getAllUniqueTagsAndStatuses(data);
		setAllTags(allTags);
		setAllStatuses(allStatuses);
		setAllLocations(allLocations);
	  } catch (error) {
		console.error("Error fetching candidates:", error);
	  }
	};
  
	fetchData();
  }, []);

  useEffect(() => {
	const filteredCandidates = candidates.filter(candidate => {
	  const matchesTags = selectedTags.length === 0 || selectedTags.some(tag => candidate.tags.map(t => t.name).includes(tag));
	  const matchesStatus = selectedStatuses.length === 0 || candidate.status.some(status => selectedStatuses.includes(status.name));
	  const matchesLocations = selectedLocations.length === 0 || selectedLocations.includes(candidate.location);
	  const matchesSearch = candidate.name.toLowerCase().includes(searchTerm.toLowerCase());
	  return matchesTags && matchesStatus && matchesSearch && matchesLocations;
	});
  
	setFilteredSlideshowCandidates(filteredCandidates);
  }, [candidates, searchTerm, selectedTags, selectedStatuses, selectedLocations]);

  return (
	<div className="portal-page">
	  <Header />
		<div className="portal-intro">
		  <div className="portal-intro-title">
			<h1 className="portal-title">Team Growth Portal</h1>
			<div className="portal-tabs">
			  <button onClick={() => handleTabChange('Search')} className={activeTab === 'Search' ? 'active' : ''}>Search</button>
			  <button onClick={() => handleTabChange('Summary')} className={activeTab === 'Summary' ? 'active' : ''}>Summary</button>
			</div>
		  </div>
		  {activeTab === 'Search' && (
			<div className="portal-intro-content">
			  <p className="portal-description">
				Explore our pool of candidates. Use the search bar to find candidates by skills, experience, or other criteria.
			  </p>
			  <div className="search-and-toggle-container">
				<input 
				  type="text"
				  className="search-bar"
				  placeholder="Search candidates..."
				  value={searchTerm}
				  onChange={handleSearchChange}
				/>
				<FontAwesomeIcon icon={faSearch} className="search-icon" />
				<button onClick={() => setIsListView(true)} className={isListView ? "view-toggle active" : "view-toggle"}>
				  <span className="tooltip">List View</span>
				  <img src={listViewIcon} alt="List View" />
				</button>
				<button onClick={() => setIsListView(false)} className={!isListView ? "view-toggle active" : "view-toggle"}>
				  <span className="tooltip">Slideshow View</span>
				  <img src={slideshowViewIcon} alt="Slideshow View" />
				</button>
			  </div>
			  <div className="filter-container">
				<div className="status-filter">
				  <label htmlFor="status-select" className="custom-label">Status:</label>
				  <Select
					id="status-select"
					isMulti
					name="statuses"
					options={statusOptions}
					className="basic-multi-select"
					classNamePrefix="select"
					onChange={selectedOptions => setSelectedStatuses(selectedOptions.map(option => option.value))}
				  />
				</div>
				<div className="specialty-filter">
				  <label htmlFor="tag-select" className="custom-label">Tag:</label>
				  <Select
					id="tag-select"
					isMulti
					name="specialties"
					options={tagOptions}
					className="basic-multi-select"
					classNamePrefix="select"
					onChange={selectedOptions => setSelectedTags(selectedOptions.map(option => option.value))}
				  />
				</div>
				<div className="location-filter">
				  <label htmlFor="location-select" className="custom-label">Location:</label>
				  <Select
					id="location-select"
					isMulti
					name="locations"
					options={allLocations.map(location => ({ value: location, label: location }))}
					className="basic-multi-select"
					classNamePrefix="select"
					onChange={selectedOptions => setSelectedLocations(selectedOptions.map(option => option.value))}
				  />
				</div>
			  </div>
			</div>
		  )}
		  {activeTab === 'Summary' && summaryData && (
			<div className='charts'>
			  <LocationBarChart data={summaryData.locationCounts} />
			  <StatusBarChart data={summaryData.statusCounts} />
			  <FunnelChart />
			  <CompensationBarChart data={summaryData.compensationStats} />
			</div>
		  )}
		</div>
		{!isListView && (
		  <div className="slideshow-text-container">
			<p>
			  Showing {currentSlide + 1} of {filteredSlideshowCandidates.length} candidates
			</p>
		  </div>
		)}
	   <div className={isListView ? 'list-view' : 'slideshow-view'}>
	   {isListView ? (
		 filteredSlideshowCandidates.length > 0 ? (
		   filteredSlideshowCandidates.map((candidate, index) => (
			 <CandidateCard key={index} candidateData={candidate} />
		   ))
		 ) : (
		   <div className="centered-message">
			 <p>No candidates found.</p>
		   </div>
		 )
			 ) : (
			 <div className="slideshow-container">
				 {filteredSlideshowCandidates.length === 0 ? (
					 <p>No matching candidates found.</p>
				 ) : (
					 <>
						 <button
							 onClick={handlePrev}
							 className="portalslideshow-prev-button"
							 disabled={filteredSlideshowCandidates.length <= 1}
						 >
							 <FontAwesomeIcon icon={faChevronLeft} />
						 </button>
						 <CandidateCard candidateData={filteredSlideshowCandidates[currentSlide]} />
						 <button
							 onClick={handleNext}
							 className="portalslideshow-next-button"
							 disabled={filteredSlideshowCandidates.length <= 1}
						 >
							 <FontAwesomeIcon icon={faChevronRight} />
						 </button>
					 </>
				 )}
			 </div>
		 )}
	   </div>
	</div>
  );
};

export default TeamPortal;
